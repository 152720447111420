<template>
<!-- 车辆管理，查询停车订单 -->
  <div class="main">
    <!-- 头部搜索栏 -->
    <el-form class="header main" label-width="120px">
      <!-- <div class="formBox">
            <label class="label">车牌号:</label>
            <el-input
            placeholder="请输入车牌号"
            class="search-input ml-15"
            clearable
            v-model.trim="searchObj.number"
            ></el-input>
      </div> -->
      <el-form-item label="手机号：" class="formBox">
            <el-input
            placeholder="请输入手机号"
            class="search-input"
            clearable
            v-model.trim="searchObj.phone"
            ></el-input>
      </el-form-item>
      <el-form-item label="丽日订单号：" class="formBox">
            <el-input
            placeholder="请输入丽日订单号"
            class="search-input"
            clearable
            v-model.trim="searchObj.orderNumber"
            ></el-input>
      </el-form-item>
      <el-form-item label="微信订单号：" class="formBox">
            <el-input
            placeholder="请输入丽日订单号"
            class="search-input"
            clearable
            v-model.trim="searchObj.transactionId"
            ></el-input>
      </el-form-item>
      <el-form-item label="其他订单号：" class="formBox">
            <el-input
            placeholder="请输入其它订单号（如物业管家）"
            class="search-input"
            clearable
            v-model.trim="searchObj.extendOrderNumber"
            ></el-input>
      </el-form-item>
      <el-form-item label="支付完成时间：" class="formBox1">
         <el-date-picker
           v-model="paymentTime"
           value-format="yyyy-MM-dd HH:mm:ss"
           type="datetimerange"
           range-separator="至"
           class="search-input"
           start-placeholder="开始日期"
           end-placeholder="结束日期"
           placeholder="请选择支付完成时间"
           @change="paymentTimeChange()"
        />
      </el-form-item>
      <el-form-item label="订单创建时间：" class="formBox1">
        <el-date-picker
          v-model="createTime"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          range-separator="至"
          class="search-input"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="createTimeChange()"
         />
      </el-form-item>
      <el-form-item label="订单状态：" class="formBox">
            <el-select v-model="searchObj.dealStatus" placeholder="请选择">
              <el-option
                v-for="item in dealStatusList"
                :key="item.status"
                :label="item.name"
                :value="item.status">
              </el-option>
  </el-select>


      </el-form-item>

      <el-form-item label="车牌号：" class="formBox">
            <el-input
            placeholder="请输入车牌号"
            class="search-input"
            clearable
            v-model.trim="searchObj.body"
            ></el-input>
      </el-form-item>
      <el-button type="primary" icon="el-icon-upload2" @click="getData(1)">查询</el-button>
      <el-button type="primary" icon="el-icon-refresh" @click="refresh()">重置</el-button>
    </el-form>
    <div class="body main">
      <el-table
       :data="tableData">
       <!-- <el-table-column prop="nickName" label="用户名">
       </el-table-column> -->
       <el-table-column prop="mobile" label="用户手机号" width="120">
       </el-table-column>
       <el-table-column prop="body" label="车牌号" width="120">
         <template slot-scope="scope">
           <span v-if="scope.row.car_no">{{scope.row.car_no}}</span>
           <span v-else-if="scope.row.body">{{scope.row.body.split('-')[2]}}</span>
           <span v-else></span>
         </template>
       </el-table-column>
       <el-table-column prop="totalFee" label="实际付款" align="center">
         <template slot-scope="scope">
           <span>{{Number(scope.row.totalFee/100)}}</span>
         </template>
       </el-table-column>
       <el-table-column prop="totalFee" label="优惠金额" align="center">
         <template slot-scope="scope">
           <span v-if="scope.row.preferentialAmount">{{Number(scope.row.preferentialAmount/100)}}</span>
           <span v-else>{{0}}</span>
         </template>
       </el-table-column>
       <el-table-column prop="useIntegral" label="使用积分" align="center" width="100">
         <template slot-scope="scope">
           <span v-if="scope.row.useIntegral">{{scope.row.useIntegral}}</span>
           <span v-else>{{0}}</span>
         </template>
       </el-table-column>
       <el-table-column prop="totalFee" label="原始金额" align="center">
         <template slot-scope="scope">
           <span v-if="scope.row.originalAmount">{{Number(scope.row.originalAmount/100)}}</span>
           <span v-else>{{0}}</span>
         </template>
       </el-table-column>
       <el-table-column prop="dealStatus" label="交易状态" align="center">
         <template slot-scope="scope">
           <span v-if="scope.row.dealStatus==1">已支付</span>
           <span v-if="scope.row.dealStatus==0">未支付</span>
         </template>
       </el-table-column>
       <el-table-column prop="body" label="停车地点" width="200">
         <template slot-scope="scope">
           <span v-if="scope.row.body">{{scope.row.parking_name}}</span>
           <span v-else></span>
         </template>
       </el-table-column>
       <el-table-column prop="timeEnd" label="支付完成时间" width="180">
       </el-table-column>
       <el-table-column prop="createTime" label="订单创建时间" width="180">
       </el-table-column>
       <el-table-column prop="payType" label="支付类型" width="120">
         <template slot-scope="scope">
           <span v-if="scope.row.payType==1"></span>
           <span v-if="scope.row.payType==2">场内停车缴费</span>
           <span v-if="scope.row.payType==3">闸口停车缴费</span>
         </template>
       </el-table-column>
       <el-table-column prop="outTradeNo" label="商户订单号" width="240">
       </el-table-column>

       <el-table-column prop="extendOrderNumber" label="其它系统订单号" width="200">
       </el-table-column>
       <el-table-column prop="transactionId" label="微信订单号" width="250">
       </el-table-column>
        <el-table-column prop="orderNumber" label="丽日订单号" width="200">
       </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNumber"
        :page-sizes="[10,20,50,100]"
        :page-size="pageSize"
        layout="total,sizes,prev,pager,next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index";
import {downFile2} from "@/utils/index.js";
import{
  getParkingList,
} from "@/api/service_center/parking"
export default {
  name: "parking_fee",
  components: {
    Pagination
  },
  data() {
    return {
     searchObj:{
       phone:'',
       body:'',
       transactionId:'',//微信订单号
       orderNumber:'',//丽日订单号
       extendOrderNumber:'',//物业系统订单号
       dealStatus:'',//交易状态
       payStartTime:'',
       payEndTime:'',
       createStartTime:'',
       createEndTime:'',
     },
     tableData:[],
    //分页
    pageNumber:1,
    pageSize:10,
    total:0,
    dealStatusList:[
      {
        status:0,
        name:"未支付"
      },
      {
        status:1,
        name:"已支付"
      }
    ],
    paymentTime:['',''],
    createTime:['','']
  }
  },
  created() {
  },
  mounted(){
    this.getData();
  },
  methods: {
    // 获取数据
    getData(e){
      if(e){
        this.pageNumber = 1
      }
      let params = {
        body:this.searchObj.body,
        phone:this.searchObj.phone,
        pageSize:this.pageSize,
        pageNumber:this.pageNumber,
        transactionId:this.searchObj.transactionId,
        orderNumber:this.searchObj.orderNumber,
        extendOrderNumber:this.searchObj.extendOrderNumber,
        dealStatus:this.searchObj.dealStatus,
        payStartTime:this.searchObj.payStartTime,
        payEndTime:this.searchObj.payEndTime,
        createStartTime:this.searchObj.createStartTime,
        createEndTime:this.searchObj.createEndTime,
      }
      console.log(params)
      getParkingList(params).then((res)=>{
        if(res.data.code == 200){
          this.tableData = res.data.body.list
          this.total = Number(res.data.body.total)
          for(let i = 0 ;i<this.tableData.length; i ++){
            if(this.tableData[i].orderJson){
              let orderJson = JSON.parse(this.tableData[i].orderJson)
              this.tableData[i].car_no = JSON.parse(this.tableData[i].orderJson).car_no
              this.tableData[i].parking_name = orderJson.parking_name ? orderJson.parking_name : (orderJson.parkingLot ? orderJson.parkingLot : this.tableData[i].body.split('-')[1])
            }else{
              this.tableData[i].car_no = ""
              this.tableData[i].parking_name = ""
            }

          }
        }
      }).catch((e)=>{
        console.log(e)
      })
    },
    // 重置
    refresh(){
      this.searchObj.phone = '';
      this.searchObj.body='';
      this.searchObj.transactionId = '';
      this.searchObj.orderNumber = '';
      this.searchObj.extendOrderNumber = '';
      this.searchObj.dealStatus = '';
      this.searchObj.payStartTime = '';
      this.searchObj.payEndTime = '';
      this.searchObj.createStartTime = '';
      this.searchObj.createEndTime = '';
      this.pageNumber = 1;
      this.pageSize = 10;
      this.paymentTime = ['',''];
      this.createTime = ['',''];
      // this.getData();
    },
    // 分页--size变化
    handleSizeChange(e){
      this.pageSize =e
      this.getData();
    },
    // 分页--页码变化
    handleCurrentChange(e){
      this.pageNumber = e
      this.getData();
    },
    // 查询---支付时间变化
    paymentTimeChange(){
      console.log(this.paymentTime)
      let tempArray = JSON.parse(JSON.stringify(this.paymentTime))
      console.log(tempArray)
      this.searchObj.payStartTime =tempArray[0]
      this.searchObj.payEndTime =tempArray[1]
    },
    // 查询---创建时间变化
    createTimeChange(){
      console.log(this.createTime)
      let tempArray = JSON.parse(JSON.stringify(this.createTime))
      this.searchObj.createStartTime =tempArray[0]
      this.searchObj.createEndTime =tempArray[1]
    }
  }
};
</script>
<style lang="scss" scoped>
.main{
  width: 100%;
}
.header{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 12px;
  padding: 12px 0 12px;
  border-bottom: 1px solid grey;
  .formBox{
    width: 25%;
      margin-bottom: 12px;
    .label{
      margin-left: 24px;
      width: 140px;
      min-width: 90px;
      display: block;
      float: left;
      line-height: 40px;
      text-align: right;
    }
    .search-input{
      width: 100%;
      float: left;
    }
  }
  .formBox1{
    width: 50%;
    margin-bottom: 12px;
    .el-date-editor{
      width: 100%;
    }
  }
}
.body{
    width: 96%;
    margin: 0 auto;
  }
.el-input{
  width:100%
}
</style>
